<template>
<div>
    <!-- Report Details -->
    <div class="vx-row">
        <div class="vx-col w-full mt-8">
            <vx-card>
                <div class="report-list">
                    <ul>
                        <li>
                            <feather-icon icon="CheckIcon"></feather-icon>
                            <div>
                                <h3>Visit Raw Data Export</h3>
                                <p>
                                    This export will show raw visit data for your site in csv
                                    format. Use this report for further analysis of your site
                                    activity. The date range is set to seven days by default and
                                    is limited to a maxium of 1 month.
                                </p>

                                <div class="date-picker p-0 review-date xl:w-auto xl:mb-0 w-full mb-2">
                                    <date-range-picker ref="picker" opens="center" :locale-data="{
                        firstDay: 0,
                        format: 'mm/dd/yyyy',
                        separator: ' - '
                      }" :dateRange="dateRange" v-model="dateRange" :alwaysShowCalendars="false" :linked-calendars="false" :ranges="ranges" @update="updateValues" @select="selectValues"></date-range-picker>
                                </div>
                                <vs-button color="secondary" type="filled" @click="getReportData()" class="small-btn">Run Visit Raw Data Export</vs-button>
                            </div>
                        </li>
                    </ul>
                </div>
            </vx-card>
        </div>
    </div>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
    components: {
        VxCard,
        DateRangePicker
    },
    filters: {
        // date(value) {
        //   if (!value) return "";
        //   let options = { month: "numeric", day: "numeric", year: "numeric" };
        //   return Intl.DateTimeFormat("en-CA", options).format(value);
        // }
    },
    data() {
        return {
            dateRange: {
                startDate: null,
                endDate: null
            },
            /* Date */
            configFromdateTimePicker: {
                minDate: null,
                maxDate: null
            },
            configTodateTimePicker: {
                minDate: null
            }
        };
    },
    computed: {
        ranges() {
            let today = new Date();
            today.setHours(0, 0, 0, 0);

            let yesterday = new Date();
            yesterday.setDate(today.getDate() - 1);
            yesterday.setHours(0, 0, 0, 0);
            return {
                Today: [today, today],
                Yesterday: [yesterday, yesterday],
                "This month": [
                    new Date(today.getFullYear(), today.getMonth(), 1),
                    new Date(today.getFullYear(), today.getMonth() + 1, 0)
                ],
                "Last month": [
                    new Date(today.getFullYear(), today.getMonth() - 1, 1),
                    new Date(today.getFullYear(), today.getMonth(), 0)
                ]
            };
        }
    },
    mounted() {
        var menuitem = document.getElementsByClassName("vs-sidebar-group ");
        menuitem.forEach(element => {
            var arrow = element.getElementsByClassName(
                "feather-icon select-none relative feather-grp-header-arrow"
            );
            var text = element.getElementsByClassName("truncate mr-3 select-none")[0]
                .innerText;
            var uls = element.getElementsByTagName("ul");
            if (text == "Leads") {
                element.className += " vs-sidebar-group-open";
                arrow[0].className += " rotate90";
                uls[0].style.maxHeight = "none";
            }
        });
    },
    beforeRouteLeave(to, from, next) {
        setTimeout(() => {
            let menuitem = [];
            menuitem = document.getElementsByClassName("vs-sidebar-group ");
            menuitem.forEach(element => {
                if (element.classList.contains("vs-sidebar-group-open")) {
                    let text = element.getElementsByClassName(
                        "truncate mr-3 select-none"
                    )[0].innerText;
                    let uls = element.getElementsByTagName("ul");
                    let arrow = element.getElementsByClassName(
                        "feather-icon select-none relative feather-grp-header-arrow"
                    );
                    let det = to.path;

                    det = det.match("/leads");
                    if (text == "Leads" && !det) {
                        uls[0].style.maxHeight = 0;
                        arrow[0].classList.remove("rotate90");
                        element.classList.remove("vs-sidebar-group-open");
                    }
                }
            });
        }, 300);
        next();
    },
    created() {
        let fromDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
        let fromDay = fromDate.getDate();
        let fromMonth = fromDate.getMonth() + 1;
        let fromYear = fromDate.getFullYear();

        let toDate = new Date();
        let toDay = toDate.getDate();
        let toMonth = toDate.getMonth() + 1;
        let toYear = toDate.getFullYear();

        this.dateRange.startDate = fromMonth + "/" + fromDay + "/" + fromYear;
        this.dateRange.endDate = toMonth + "/" + toDay + "/" + toYear;
    },
    methods: {
        /* Date */
        /*onFromChange(selectedDates, dateStr) {
                  this.$set(this.configTodateTimePicker, "minDate", dateStr);
                },
                onToChange(selectedDates, dateStr) {
                  this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
                },*/
        selectValues(values) {
            let date1 = values.startDate;
            let date2 = values.endDate;
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays > 31) {
                this.dateRange.startDate = new Date(
                    date2.getFullYear(),
                    date2.getMonth() - 1,
                    date2.getDate()
                );
                this.dateRange.endDate = new Date(
                    date2.getFullYear(),
                    date2.getMonth(),
                    date2.getDate()
                );
            }
        },
        updateValues(values) {
            this.dateRange.startDate = moment(values.startDate).format("MM/DD/YYYY");
            this.dateRange.endDate = moment(values.endDate).format("MM/DD/YYYY");
        },
        getReportData() {
            let fileName = "Visit_Raw_Data_Report.csv";
            this.axios
                .get(
                    "ws/MyReports/GetVisitRawDataExportReport?startDate=" +
                    this.dateRange.startDate +
                    "&endDate=" +
                    this.dateRange.endDate, {
                        responseType: "blob"
                    }
                )
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: "text/csv"
                        })
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                });
        }
    }
};
</script>
